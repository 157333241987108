import { ActionTypes } from '../actions';

const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

const defaultState = { referrer: websiteUrl };

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.SAVE_REFERRER:
        return {
          ...state,
          referrer: action.payload,
        };

      default:
        return state;
    }
  } catch (error) {
    console.log('Error in navigation reducer:', error);
  }

  return null;
};
