import { applyMiddleware, createStore, compose } from 'redux';
import allReducers from './reducers';
import { sagaMiddleware, sagas } from './sagas';

let createStoreWithMiddleware;

export default (initialState) => {
  if (process.env.NODE_ENV === 'production') {
    createStoreWithMiddleware = applyMiddleware(sagaMiddleware)(createStore);
  } else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    createStoreWithMiddleware = composeEnhancers(applyMiddleware(
      sagaMiddleware,
    ))(createStore);
  }

  const store = createStoreWithMiddleware(allReducers, initialState);

  // Start all sagas.
  // eslint-disable-next-line no-restricted-syntax
  for (const saga of sagas) {
    sagaMiddleware.run(saga);
  }

  return store;
};
