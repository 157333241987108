import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { auth, onAuthStateChanged } from './firebase';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Breadcrumbs from './components/common/Breadcrumbs';
import Search from './components/common/Search';
import GuideCategories from './components/GuideCategories';
import Guide from './components/Guide';
import ProfessionalCategories from './components/ProfessionalCategories';
import Professionals from './components/Professionals';
import configureStore from './store';

ReactGA.initialize('UA-165836730-1');

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  return (
    <Provider store={configureStore()}>
      <div className="d-flex flex-column h-100">
        <Header />
        <div className="d-flex flex-column flex-grow-1">
          <Breadcrumbs />
          <div className="container content-container d-flex flex-column flex-grow-1">
            <SlideRoutes duration={500}>
              <Route path="/" element={<Search />} />
              <Route path="guide" element={<GuideCategories />} />
              <Route path="guide/:category" element={<Guide />} />
              <Route path="professionals" element={<ProfessionalCategories />} />
              <Route path="professionals/:category" element={<Professionals />} />
            </SlideRoutes>
          </div>
        </div>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
