import createSagaMiddleware from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';

import timelineSagas from './timeline';
import professionalsSagas from './professionals';

export const sagaMiddleware = createSagaMiddleware();

function createWatcher(actionSagaPair) {
  // eslint-disable-next-line func-names
  return function* () {
    yield takeEvery(...actionSagaPair);
  };
}

export const sagas = [
  ...timelineSagas,
  ...professionalsSagas,
].map(createWatcher);
