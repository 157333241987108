/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
export function upperSnakeCase(string) {
  return string.split(' ').join('_').toUpperCase();
}

export function camelCase(string) {
  const words = string.toLowerCase().split(/_| /);
  return words.reduce(
    (word, next) => word + next[0].toUpperCase() + next.slice(1),
  );
}

export function getIconName(label) {
  return label.toLowerCase().split(' ').join('-');
}

export function useQueryParams() {
  const params = new URLSearchParams(
    window ? window.location.search : {},
  );

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
}

export const validateName = (name) => {
  const has_number = /\d/.test(name);
  const has_special_character = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
    name,
  );

  return !(has_special_character || has_number);
};

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '').substring(0, 10);
  const areaCode = cleaned.substring(0, 3);
  const middle = cleaned.substring(3, 6);
  const last = cleaned.substring(6, 10);

  if (cleaned.length > 6) { return `(${areaCode}) ${middle}-${last}`; }
  if (cleaned.length > 3) { return `(${areaCode}) ${middle}`; }
  if (cleaned.length > 0) { return `(${areaCode}`; }
  return '';
}

export function formatDate(dateString) {
  switch (dateString.length) {
    case 0:
      return '';
    case 1:
      if (/^(0|1)$/.test(dateString)) {
        return dateString;
      }
      break;
    case 2:
      if (/^(0[1-9]|1[0-2])$/.test(dateString)) {
        return dateString;
      }
      break;
    case 3:
      if (/^(0[1-9]|1[0-2])\/$/.test(dateString)) {
        return dateString;
      } if (/^(0[1-9]|1[0-2])(0|1|2|3)$/.test(dateString)) {
        return `${dateString.slice(0, -1)}/${dateString.slice(-1)}`;
      }
      break;
    case 4:
      if (/^(0[1-9]|1[0-2])\/(0|1|2|3)$/.test(dateString)) {
        return dateString;
      }
      break;
    case 5:
      if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])$/.test(dateString)) {
        return dateString;
      }
      break;
    case 6:
      if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/$/.test(dateString)) {
        return dateString;
      } if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])(1|2)$/.test(dateString)) {
        return `${dateString.slice(0, -1)}/${dateString.slice(-1)}`;
      }
      break;
    case 7:
      if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(1|2)$/.test(dateString)) {
        return dateString;
      }
      break;
    case 8:
      if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)$/.test(dateString)) {
        return dateString;
      }
      break;
    case 9:
      if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{1}$/.test(dateString)) {
        return dateString;
      }
      break;
    case 10:
      if (/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(dateString)) {
        return dateString;
      }
      break;
    default:
      return null;
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDisplayDate(date = new Date()) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
