/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import {
  Form, Col, Row,
  Dropdown,
} from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

function StreetInput({
  label, addresses, onChange, onBlur = () => { },
}) {
  const [search, setSearch] = useState('');

  const timer = useRef();
  useEffect(() => () => {
    clearTimeout(timer.current);
  }, []);
  const setFieldVal = (key, value) => {
    const newAddress = { ...addresses, [key]: value };
    onChange(newAddress);
  };

  if (!addresses) {
    return null;
  }

  return (
    <>
      <Row className="mt-3 mb-1">
        <h4>{label}</h4>
      </Row>
      {!addresses.city ? (
        <Row>
          <Col xs={12} md={6} className="form-item">
            <PlacesAutocomplete
              value={search}
              onChange={(text) => {
                setSearch(text);
              }}
              onSelect={(address) => {
                geocodeByAddress(address).then((results) => {
                  setSearch(address);
                  if (results.length > 0) {
                    const result = results[0];

                    let state = '';
                    let city = '';
                    let zipCode = '';
                    let addressLine = '';
                    let addressLine2 = '';

                    result.address_components.forEach((component) => {
                      const componentType = component.types[0];
                      switch (componentType) {
                        case 'street_number':
                          addressLine += `${component.long_name} `;
                          break;

                        case 'administrative_area_level_1':
                          state = component.long_name;
                          break;

                        case 'locality':
                        case 'sublocality':
                          city = component.long_name;
                          break;

                        case 'postal_code':
                          zipCode = component.long_name;
                          break;

                        case 'route':
                          addressLine += component.long_name;
                          break;

                        case 'neighborhood':
                          addressLine2 = component.long_name;
                          break;
                        default:
                          break;
                      }

                      if (component.types.includes('sublocality')) { city = component.long_name; }
                    });

                    onChange({
                      state, city, zipCode, addressLine, addressLine2,
                    });
                  }
                });
              }}
              searchOptions={{
                componentRestrictions: { country: ['us'] },
                types: ['address'],
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <Form.Group className="dropdown-holder">
                  <Form.Control
                    type="location"
                    {...getInputProps({
                      placeholder: 'Address, neighbourhood, city, zipcode',
                    })}
                    onBlur={() => { }}
                  />
                  <Dropdown.Menu
                    className="dropdown"
                    show={loading || suggestions.length}
                  >
                    {loading && (
                    <Dropdown.Item key="loading">Loading...</Dropdown.Item>
                    )}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <Dropdown.Item
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          {suggestion.description}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Form.Group>
              )}
            </PlacesAutocomplete>
          </Col>
        </Row>
      )
        : (
          <>
            <Row>
              <Col xs={12} md={6} className="form-item">
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={addresses.addressLine}
                    onChange={(e) => {
                      clearTimeout(timer.current);
                      setFieldVal('addressLine', e.target.value);
                      timer.current = setTimeout(() => {
                        onBlur();
                      }, 3000);
                    }}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="form-item">
                <Form.Group>
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    value={addresses.addressLine2}
                    onChange={(e) => {
                      clearTimeout(timer.current);
                      setFieldVal('addressLine2', e.target.value);
                      timer.current = setTimeout(() => {
                        onBlur();
                      }, 3000);
                    }}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4} className="form-item">
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    value={addresses.city}
                    onChange={(e) => {
                      clearTimeout(timer.current);
                      setFieldVal('city', e.target.value);
                      timer.current = setTimeout(() => {
                        onBlur();
                      }, 3000);
                    }}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} md={4} className="form-item">
                <Form.Group>
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="text"
                    value={addresses.zipCode}
                    onChange={(e) => {
                      clearTimeout(timer.current);
                      setFieldVal('zipCode', e.target.value);
                      timer.current = setTimeout(() => {
                        onBlur();
                      }, 3000);
                    }}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4} className="form-item">
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    value={addresses.state}
                  // disabled
                    onChange={(e) => {
                      clearTimeout(timer.current);
                      setFieldVal('state', e.target.value);
                      timer.current = setTimeout(() => {
                        onBlur();
                      }, 3000);
                    }}
                    onBlur={onBlur}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
    </>
  );
}

export default StreetInput;
