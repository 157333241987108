import React from 'react';

function GuideContent({ Info, StepNumber }) {
  const renderParagraph = (paragraph, key) => {
    switch (paragraph.type) {
      case 'paragraph':
        return <p key={key}>{paragraph.text}</p>;
      case 'header':
        return (
          <p key={key}>
            <strong>{paragraph.text}</strong>
          </p>
        );
      case 'dashList':
        return (
          <p key={key} className="ms-2">
            <strong>
              {paragraph.header}
            </strong>
            {' - '}
            {paragraph.text}
          </p>
        );

      case 'listParagraph':
        return <p key={key} className="ms-2">{paragraph.text}</p>;
      case 'br':
        return <br key={key} />;
      case 'dotsParagraph':
        return (
          <ul key={key}>
            {paragraph.texts.map((text, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                {text}
              </li>
            ))}
          </ul>
        );
      case 'marginParagraph':
        return <p key={key}>{paragraph.text}</p>;
      default:
        break;
    }
  };

  return (
    <div className="guide-content">
      {Info[StepNumber].map((info, index) => renderParagraph(info, index))}
    </div>
  );
}

export default GuideContent;
