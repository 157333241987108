import { combineReducers } from 'redux';
import search from './search';
import navigation from './navigation';
import timeline from './timeline';
import professionals from './professionals';

export default combineReducers({
  search,
  navigation,
  timeline,
  professionals,
});
