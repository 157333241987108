import { ActionTypes } from '../actions';

const defaultState = {};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_TIMELINE:
        return {
          ...state,
          isTimelineLoading: true,
        };
      case ActionTypes.LOADED_TIMELINE:
        return {
          ...state,
          timeline: action.payload.timeline,
          isTimelineLoading: false,
        };

      case ActionTypes.LOADING_STEP_INFO:
        return {
          ...state,
          isStepInfoLoading: true,
        };

      case ActionTypes.LOADED_STEP_INFO:
        return {
          ...state,
          stepInfo: action.payload.stepInfo,
          isStepInfoLoading: false,
        };

      default:
        return state;
    }
  } catch (error) {
    console.log('Error in timeline reducer:', error);
  }

  return null;
};
