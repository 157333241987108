export const WhenPlanList = [
  'I am just starting out',
  'I am actively looking and making offers',
  'I have an accepted offer',
];

export const BoroughList = [
  'Bronx',
  'Brooklyn',
  'Manhattan',
  'Queens',
  'Staten Island',
  'Not buying in NYC',
];

export const DownpaymentPercentList = [
  {
    value: -1,
    label: '',
  },
  {
    value: 0,
    label: '0-5%',
  },
  {
    value: 6,
    label: '6-10%',
  },
  {
    value: 11,
    label: '11-15%',
  },
  {
    value: 16,
    label: '16-20%',
  },
  {
    value: 21,
    label: '21-30%',
  },
  {
    value: 31,
    label: '31-40%',
  },
  {
    value: 41,
    label: '41-50%',
  },
  {
    value: 51,
    label: '51-60%',
  },
  {
    value: 61,
    label: '61-70%',
  },
  {
    value: 71,
    label: '71-80%',
  },
  {
    value: 81,
    label: '81-90%',
  },
  {
    value: 91,
    label: 'Greater than 90%',
  },
];

export const CreditScoreList = [
  '',
  'Poor (<=639)',
  'Fair (640-679)',
  'Good (680-719)',
  'Excellent (>=720)',
  "I don't know",
];

export const MaxPriceList = [
  {
    value: 0,
    name: '',
  },
  {
    value: 100000,
    name: '100k',
  },
  {
    value: 200000,
    name: '200k',
  },
  {
    value: 300000,
    name: '300k',
  },
  {
    value: 400000,
    name: '400k',
  },
  {
    value: 500000,
    name: '500k',
  },
  {
    value: 600000,
    name: '600k',
  },
  {
    value: 700000,
    name: '700k',
  },
  {
    value: 800000,
    name: '800k',
  },
  {
    value: 900000,
    name: '900k',
  },
  {
    value: 1000000,
    name: '1M',
  },
  {
    value: 1250000,
    name: '1.25M',
  },
  {
    value: 1500000,
    name: '1.5M',
  },
  {
    value: 1750000,
    name: '1.75M',
  },
  {
    value: 2000000,
    name: '2M',
  },
  {
    value: 3000000,
    name: '3M',
  },
  {
    value: 4000000,
    name: '4M',
  },
  {
    value: 5000000,
    name: '5M+',
  },
];

export const TypePropertyList = [
  'Condo or Co-op',
  'Single Family or Townhouse',
];

export const OccupancyList = [
  'Occupied', 'Vacant',
];

export const MoverTypePropertyList = [
  'Apartment, Condo or Co-Op',
  'Single Family or Townhouse',
];

export const ElevatorList = [
  'Yes',
  'No',
];

export const MainKeys = {
  general: ['City', 'MaximumPrice', 'PurchasePrice', 'PurchaseAddress'],
  contact: ['FirstName', 'LastName', 'Phone', 'DateOfBirth', 'Email'],
  mortgageBrokers: ['City', 'MaximumPrice', 'DownPaymentPercent', 'CreditScore', 'HouseholdIncome'],
  realEstateAgents: ['City', 'MaximumPrice', 'AdditionalInformation', 'SellCurrentProperty'],
  realEstateAttorneys: ['PurchaseAddress', 'PurchasePrice'],
  homeInspectors: ['PurchaseAddress', 'PropertyType', 'SquareFootage', 'Occupancy', 'HomeInspectionDate'],
  insuranceAgents: ['PurchaseAddress', 'PurchasePrice'],
  movers: ['MoveDate', 'CurrentAddress', 'FromSquareFootage', 'FromPropertyType', 'FromHasElevator', 'FromFloor',
    'PurchaseAddress', 'ToSquareFootage', 'PropertyType', 'ToHasElevator', 'ToFloor'],
};

export const PropertyTypeList = [
  '',
  'Apartment, Condo or Co-Op',
  'Single Family or Townhouse',
];

export const PropertyUseList = [
  '',
  'Primary Home',
  'Secondary Home',
  'Rental Property',
];
