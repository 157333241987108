/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Dropdown,
} from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Actions } from '../../actions';
import { useQueryParams } from '../../utils';

function AutoComplete() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState();

  useEffect(() => {
    const { search, city, state } = location.state ?? useQueryParams();

    if (search && city && state) {
      dispatch(Actions.searchChanged({ search, state, city }));
    }
    if (search) {
      setSearchText(search);
    }
  }, []);

  useEffect(() => {
    if (searchResult) {
      dispatch(Actions.searchChanged(searchResult));
    }
  }, [searchResult]);

  return (
    <PlacesAutocomplete
      value={searchText}
      onChange={(text) => {
        setSearchText(text);
        if (text === '') {
          setSearchResult({});
        }
      }}
      onSelect={(address) => {
        geocodeByAddress(address).then((results) => {
          setSearchText(address);
          if (results.length > 0) {
            const result = results[0];

            let state = '';
            let city = '';

            result.address_components.forEach((component) => {
              if (
                component.types.includes('sublocality')
                || component.types.includes('locality')
              ) {
                city = component.long_name;
              }

              if (
                component.types.includes('administrative_area_level_1')
              ) {
                state = component.long_name;
              }
            });

            setSearchResult({
              search: address,
              state,
              city,
            });
          }
        });
      }}
      searchOptions={{
        componentRestrictions: { country: ['us'] },
        types: ['geocode'],
      }}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }) => (
        <Form.Group className="dropdown-holder">
          <Form.Control
            type="location"
            {...getInputProps({
              placeholder: 'Address, neighbourhood, city, zipcode',
            })}
            onBlur={() => { }}
          />
          <Dropdown.Menu
            className="dropdown"
            show={loading || suggestions.length}
          >
            {loading && (
              <Dropdown.Item key="loading">Loading...</Dropdown.Item>
            )}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <Dropdown.Item
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  {suggestion.description}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Form.Group>
      )}
    </PlacesAutocomplete>
  );
}

export default AutoComplete;
