export const professionalCategories = [
  { id: 1, name: 'Mortgages', enum: 'MortgageBrokers' },
  { id: 2, name: 'Real Estate Agents', enum: 'RealEstateAgents' },
  { id: 3, name: 'Attorneys', enum: 'Attorneys' },
  { id: 4, name: 'Home Inspectors', enum: 'HomeInspectors' },
  { id: 5, name: 'Home Insurance', enum: 'HomeownersInsurance' },
  { id: 6, name: 'Moving companies', enum: 'Movers' },
];

export function getOffset(element) {
  const rect = element.getBoundingClientRect();
  return rect.top;
}
