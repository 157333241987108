/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useQueryParams } from '../../utils';
import { Actions } from '../../actions';
import { professionalCategories } from '../../utils/const';

function SearchBreadcrumb({ match, location }) {
  const search = useSelector((state) => state.search);
  const navigation = useSelector((state) => state.navigation);

  if (location.pathname === match.pathname) {
    return (
      <>
        <Breadcrumb.Item
          key="Home"
          href={navigation.referrer}
        >
          Hyponia
        </Breadcrumb.Item>
        <Breadcrumb.Item
          key="Search"
          active
        >
          Search
        </Breadcrumb.Item>
      </>
    );
  }

  const breadcrumb = `${search.city}, ${search.state}`;
  return (
    <>
      <Breadcrumb.Item
        href={navigation.referrer}
      >
        Hyponia
      </Breadcrumb.Item>
      <Breadcrumb.Item
        key={breadcrumb}
        linkAs={Link}
        linkProps={{
          to: match.pathname,
          state: {
            ...search,
          },
        }}
      >
        {breadcrumb}
      </Breadcrumb.Item>
    </>
  );
}

function GuideBreadcrumb({ match }) {
  const { stepInfo } = useSelector((state) => state.timeline);
  const search = useSelector((state) => state.search);

  if (!stepInfo) {
    return (
      <Breadcrumb.Item
        key="Guide"
        active
      >
        Guide
      </Breadcrumb.Item>
    );
  }

  return (
    <Breadcrumb.Item
      key="Guide"
      linkAs={Link}
      linkProps={{
        to: match.pathname,
        state: {
          ...search,
        },
      }}
    >
      Guide
    </Breadcrumb.Item>
  );
}

function GuideCategoryBreadcrumb() {
  const { stepInfo } = useSelector((state) => state.timeline);

  if (!stepInfo) {
    return null;
  }

  const breadcrumb = stepInfo.name;
  return (
    <Breadcrumb.Item
      key={breadcrumb}
      active
    >
      {breadcrumb}
    </Breadcrumb.Item>
  );
}

function ProfessionalsBreadcrumb({ match }) {
  const { category } = useSelector((state) => state.professionals);
  const search = useSelector((state) => state.search);

  if (!category) {
    return (
      <Breadcrumb.Item
        key="Professionals"
        active
      >
        Professionals
      </Breadcrumb.Item>
    );
  }

  return (
    <Breadcrumb.Item
      key="Professionals"
      linkAs={Link}
      linkProps={{
        to: match.pathname,
        state: {
          ...search,
        },
      }}
    >
      Professionals
    </Breadcrumb.Item>
  );
}

function ProfessionalsCategoryBreadcrumb() {
  const { category } = useSelector((state) => state.professionals);

  if (!category) {
    return null;
  }

  const breadcrumb = professionalCategories.find((p) => p.enum === category).name;
  return (
    <Breadcrumb.Item
      key={breadcrumb}
      active
    >
      {breadcrumb}
    </Breadcrumb.Item>
  );
}

const routes = [
  { path: '/', breadcrumb: SearchBreadcrumb, exact: true },
  { path: '/guide', breadcrumb: GuideBreadcrumb },
  { path: '/guide/:category', breadcrumb: GuideCategoryBreadcrumb },
  { path: '/professionals', breadcrumb: ProfessionalsBreadcrumb },
  { path: '/professionals/:category', breadcrumb: ProfessionalsCategoryBreadcrumb },
];

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes);
  const dispatch = useDispatch();
  const location = useLocation();

  // from react navigation
  useEffect(() => {
    if (location.state) {
      const search = location.state;

      if (search) {
        dispatch(Actions.searchChanged(search));
      }
    }
  }, [location.state]);

  // from url
  useEffect(() => {
    if (window.location.search) {
      const { search, state, city } = useQueryParams();

      if (search && city && state) {
        dispatch(Actions.searchChanged({ search, state, city }));
      }
    }
  }, [window.location.search]);

  return (
    <Breadcrumb className="container breadcrumbs-container">
      {breadcrumbs.map(({
        breadcrumb,
      }) => (
        breadcrumb
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
