import { ActionTypes } from '../actions';

const defaultState = {};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.SEARCH_CHANGED:
        return {
          ...state,
          search: action.payload.search,
          state: action.payload.state,
          city: action.payload.city,
        };

      default:
        return state;
    }
  } catch (error) {
    console.log('Error in navigation reducer:', error);
  }

  return null;
};
