import React from 'react';
import { render } from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('REACT_APP_API_URL=', process.env.REACT_APP_API_URL);
}

render(
  <Router>
    <App />
  </Router>,
  document.getElementById('app'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
