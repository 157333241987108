import React from 'react';

function Tips({ tips }) {
  return (
    <div className="guide-content">
      {
        tips.map((tip) => (
          <React.Fragment key={tip.title}>
            <strong>{tip.title}</strong>
            <p>{tip.text}</p>
          </React.Fragment>
        ))
      }
    </div>
  );
}

export default Tips;
