/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
const apiUrl = process.env.REACT_APP_API_URL;

/**
 * parseToJsonPromise
 * Returns JSON result for the fetch response
 * e.g. parseToJsonPromise(data) => { ...values }
 *
 * */

function parseToJsonPromise(data) {
  return new Promise((res) => {
    data
      .json()
      .then((object) => {
        const response = {
          successful: [200, 201].includes(data.status),
          data: object,
          code: data.status,
        };
        return res(response);
      })
      .catch(() => {
        res({
          successful: false,
          data: undefined,
          code: data.status,
        });
      });
  });
}

const apiGenerator = (url, uri, method, payload, type) => {
  const accessToken = sessionStorage.getItem('idToken');

  const headers = {
    Accept: 'application/json',
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  if (type !== 'file') {
    headers['Content-Type'] = 'application/json';
    payload = JSON.stringify(payload);
  }

  return fetch(`${url}${uri}`, {
    method,
    body: payload,
    headers,
  })
    .then((data) => parseToJsonPromise(data))
    .catch((error) => {
      console.log(error);

      return {
        successful: false,
        data: undefined,
        code: error.status_code,
      };
    });
};

const ApiGenerator = (uri, method, payload, type) => apiGenerator(apiUrl, uri, method, payload, type);

const networkAdapter = (generator) => ({
  POST: (url, payload, type) => generator(url, 'POST', payload, type),
  PUT: (url, payload) => generator(url, 'PUT', payload),
  DELETE: (url) => generator(url, 'DELETE'),
  GET: (url, token, payload) => generator(url, 'GET', payload),
});

/**
 *
 * @type {{POST: ((p1?:*, p2?:*)), PUT: ((p1?:*, p2?:*)), DELETE: ((p1?:*)), GET: ((p1?:*))}}
 * @private
 */

// eslint-disable-next-line no-underscore-dangle
const _requestAPI = networkAdapter(ApiGenerator);

export const loadTimeline = (payload) => _requestAPI.GET(`/v1/Guide/Timeline?state=${payload?.state}&city=${payload?.city}`, payload);
export const loadStep = (payload) => _requestAPI.GET(`/v1/Guide/Step?stepId=${payload}`);
export const searchProfessionals = (payload) => _requestAPI.POST('/v1/Professionals/Search', payload);
export const signUpOrLogIn = () => _requestAPI.GET('/v1/User/SignUpOrLogIn');
export const UpdateUser = (payload) => _requestAPI.POST('/v1/User/UpdateUser', payload);
export const ContactProfessionals = (payload) => _requestAPI.POST('/v1/User/ContactProfessionals', payload);
export const SavePurchaseInfo = (payload) => _requestAPI.POST('/v1/User/SavePurchaseInfo', payload);
export const SaveFinancialInfo = (payload) => _requestAPI.POST('/v1/User/SaveFinancialInfo', payload);
export const SaveProperty = (payload) => _requestAPI.POST('/v1/User/SaveProperty', payload);
export const SaveMove = (payload) => _requestAPI.POST('/v1/User/SaveMove', payload);
