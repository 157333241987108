import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';

import {
  loadTimeline,
  loadStep,
} from './networks';

function* getTimeline({ payload }) {
  yield put(Actions.loadingTimeline());

  const response = yield call(loadTimeline, payload);

  if (!response.successful) {
    console.log('Error', response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.loadedTimeline({
      timeline: response.data,
    }));
  }
}

function* getStepInfo({ payload }) {
  yield put(Actions.loadingStepInfo(payload));

  const response = yield call(loadStep, payload);

  if (!response.successful) {
    console.log('Error', response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.loadedStepInfo({
      stepInfo: response.data,
    }));
  }
}

export default [
  [ActionTypes.GET_TIMELINE, getTimeline],
  [ActionTypes.GET_STEP_INFO, getStepInfo],
];
