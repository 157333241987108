/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Container, Modal, Row, Button, Form,
} from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import NumberFormat from 'react-number-format';
import StreetInput from '../common/StreetInput';
import './index.scss';
import {
  // WhenPlanList,
  OccupancyList,
  ElevatorList,
  CreditScoreList,
  // BoroughList,
  MaxPriceList,
  DownpaymentPercentList,
  // MoverTypePropertyList,
  PropertyTypeList,
  PropertyUseList,
} from './constant';
import {
  validateName,
  formatPhoneNumber,
  validateEmail,
  formatDisplayDate,
} from '../../utils';
import { Actions } from '../../actions';

export default function ProfessionalsRequestQuotes({ show, handleClose, category }) {
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState('left');
  const [userInfo, setUserInfo] = useState({});
  const [financialInfo, setFinancialInfo] = useState({});
  const [purchaseInfo, setPurchaseInfo] = useState({
    sellCurrentProperty: false,
  });
  const [moverInfo, setMoverInfo] = useState({
    fromProperty: {
      address: {},
    },
    toProperty: {
      address: {},
    },
  });
  const [contactInfo, setContactInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [errors, setErrors] = useState({});

  const { professionals } = useSelector((state) => state.professionals);

  const getLastStep = () => {
    if (category === 'Movers') return 3;
    return 2;
  };

  const setFieldVal = (key, value) => {
    const newValue = key === 'maximumPrice'
      || key === 'purchasePrice'
      || key === 'squareFootage'
      ? parseInt(value)
      : value;

    setUserInfo((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  const setPurchaseVal = (key, value) => {
    const newValue = key === 'maximumPrice' ? parseInt(value) : value;

    setPurchaseInfo((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  const setContactVal = (key, value) => {
    setContactInfo((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const setFinancialVal = (key, value) => {
    setFinancialInfo((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const setMoverFrom = (key, value) => {
    setMoverInfo((prevState) => ({
      ...prevState,
      fromProperty: {
        ...prevState.fromProperty,
        [key]: value,
      },
    }));
  };

  const setMoverTo = (key, value) => {
    setMoverInfo((prevState) => ({
      ...prevState,
      toProperty: {
        ...prevState.toProperty,
        [key]: value,
      },
    }));
  };

  useEffect(() => {
    if (category === 'HomeInspectors') {
      setFieldVal('occupancy', true);
      setFieldVal('squareFootage', 0);
    }
    setFieldVal('address', {
      id: 0,
      addressLine: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US',
    });
  }, [category]);

  const renderForms = () => {
    switch (category) {
      case 'MortgageBrokers':
        return (
          <Row>
            <Col
              md="auto"
              xs={12}
              className="flex-md-grow-1 flex-md-shrink-1 content"
            >
              <Row>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="propertyType">
                    <Form.Label>Property Type</Form.Label>
                    <Form.Control
                      value={purchaseInfo.propertyType}
                      as="select"
                      onChange={(e) => setPurchaseVal('propertyType', e.target.value)}
                    >
                      {PropertyTypeList.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="propertyUse">
                    <Form.Label>Property Use</Form.Label>
                    <Form.Control
                      value={purchaseInfo.propertyUse}
                      as="select"
                      onChange={(e) => setPurchaseVal('propertyUse', e.target.value)}
                    >
                      {PropertyUseList.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="maximumPrice">
                    <Form.Label>Maximum Price</Form.Label>
                    <Form.Control
                      value={purchaseInfo.maximumPrice}
                      as="select"
                      onChange={(e) => setPurchaseVal('maximumPrice', e.target.value)}
                    >
                      {MaxPriceList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.value === 0 ? '' : `$${item.name}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="downPaymentPercent">
                    <Form.Label>Down payment</Form.Label>
                    <Form.Control
                      value={financialInfo.downPaymentPercent}
                      as="select"
                      onChange={(e) => setFinancialVal('downPaymentPercent', e.target.value)}
                    >
                      {DownpaymentPercentList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="creditScore">
                    <Form.Label>Estimated Credit Score</Form.Label>
                    <Form.Control
                      defaultValue={financialInfo.creditScore}
                      as="select"
                      onChange={(e) => setFinancialVal('creditScore', e.target.value)}
                    >
                      {CreditScoreList.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="householdIncome">
                    <Form.Label>Annual Income of borrower(s)</Form.Label>
                    <NumberFormat
                      value={financialInfo.householdIncome}
                      thousandSeparator
                      prefix="$"
                      onValueChange={(e) => setFinancialVal('householdIncome', e.value)}
                      inputMode="numeric"
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        );

      case 'RealEstateAgents':
        return (
          <Row>
            <Col
              md="auto"
              xs={12}
              className="flex-md-grow-1 flex-md-shrink-1 content"
            >
              <Row>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="maximumPrice">
                    <Form.Label>Maximum Price</Form.Label>
                    <Form.Control
                      value={purchaseInfo.maximumPrice}
                      as="select"
                      onChange={(e) => setPurchaseVal('maximumPrice', e.target.value)}
                    >
                      {MaxPriceList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.value === 0 ? '' : `$${item.name}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="form-item">
                  <Form.Group controlId="additionalInformation">
                    <Form.Label>
                      Additional home buying criteria (optional)
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={purchaseInfo.additionalInformation}
                      onChange={(e) => setPurchaseVal('additionalInformation', e.target.value)}
                      placeholder="e.g. neighborhood preference"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="form-item-single-checkbox">
                  <Form.Group controlId="sellCurrentProperty">
                    <Form.Check
                      type="checkbox"
                      defaultChecked={purchaseInfo.sellCurrentProperty}
                      label="I also need help selling my current home"
                      onChange={() => setPurchaseInfo({
                        ...purchaseInfo,
                        sellCurrentProperty:
                            !purchaseInfo.sellCurrentProperty,
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        );

      case 'Attorneys':
        return (
          <Row>
            <Col
              md="auto"
              xs={12}
              className="flex-md-grow-1 flex-md-shrink-1 content"
            >
              <Row>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group>
                    <Form.Label>Purchase Price</Form.Label>
                    <Form.Control
                      value={userInfo.purchasePrice}
                      as="select"
                      onChange={(e) => setFieldVal('purchasePrice', e.target.value)}
                    >
                      {MaxPriceList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.value === 0 ? '' : `$${item.name}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="form-item-single-checkbox">
                  <Form.Group controlId="knowPurchaseAddress">
                    <Form.Check
                      type="checkbox"
                      defaultChecked={userInfo.knowPurchaseAddress}
                      label="I know the property address I want to purchase"
                      onChange={() => setFieldVal(
                        'knowPurchaseAddress',
                        !userInfo.knowPurchaseAddress,
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {userInfo.knowPurchaseAddress && (
                <StreetInput
                  label="Address of home to be purchased"
                  addresses={userInfo.address}
                  onChange={(add) => setFieldVal('address', add)}
                />
              )}
            </Col>
          </Row>
        );

      case 'HomeInspectors':
        return (
          <Row>
            <Col
              md="auto"
              xs={12}
              className="flex-md-grow-1 flex-md-shrink-1 content"
            >
              <Row>
                <Col
                  xs={12}
                  md={6}
                  className="d-flex d-align-items-center form-item"
                >
                  <Form.Group>
                    <Form.Label>
                      Home inspection to be completed on or before
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={formatDisplayDate(userInfo.homeInspectionDate)}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (value || value === '') {
                          setFieldVal('homeInspectionDate', new Date(value));
                        }
                      }}
                      placeholder="MM/DD/YYYY"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group controlId="propertyType">
                    <Form.Label>Property Type</Form.Label>
                    <Form.Control
                      value={userInfo.propertyType}
                      as="select"
                      onChange={(e) => setFieldVal('propertyType', e.target.value)}
                    >
                      {PropertyTypeList.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={6} md={3} className="form-item">
                  <Form.Group controlId="squareFootage">
                    <Form.Label>Square Footage</Form.Label>
                    <Form.Control
                      type="number"
                      value={userInfo.squareFootage}
                      onChange={(e) => setFieldVal('squareFootage', e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs={6}
                  md={3}
                  className="d-flex d-align-items-center form-item"
                >
                  <Form.Group>
                    <Form.Label>Occupancy</Form.Label>
                    <div className="form-radios">
                      {OccupancyList.map((item) => (
                        <Form.Check
                          key={item}
                          name="occupancy"
                          label={item}
                          type="radio"
                          id={`inline-radio-${item}`}
                          // eslint-disable-next-line no-bitwise
                          checked={
                            !(userInfo.occupancy ^ (item === 'Occupied'))
                          }
                          onChange={() => setFieldVal('occupancy', item === 'Occupied')}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <StreetInput
                label="Address of home to be purchased"
                addresses={userInfo.address}
                onChange={(add) => setFieldVal('address', add)}
              />
            </Col>
          </Row>
        );

      case 'HomeownersInsurance':
        return (
          <Row>
            <Col
              md="auto"
              xs={12}
              className="flex-md-grow-1 flex-md-shrink-1 content"
            >
              <Row>
                <Col xs={12} md={6} className="form-item">
                  <Form.Group>
                    <Form.Label>Purchase Price</Form.Label>
                    <Form.Control
                      value={userInfo.purchasePrice}
                      as="select"
                      onChange={(e) => setFieldVal('purchasePrice', e.target.value)}
                    >
                      {MaxPriceList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.value === 0 ? '' : `$${item.name}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="form-item-single-checkbox">
                  <Form.Group controlId="knowPurchaseAddress">
                    <Form.Check
                      type="checkbox"
                      defaultChecked={userInfo.knowPurchaseAddress}
                      label="I know the property address I want to purchase"
                      onChange={() => setFieldVal(
                        'knowPurchaseAddress',
                        !userInfo.knowPurchaseAddress,
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {userInfo.knowPurchaseAddress && (
                <StreetInput
                  label="Address of home to be purchased"
                  addresses={userInfo.address}
                  onChange={(add) => setFieldVal('address', add)}
                />
              )}
            </Col>
          </Row>
        );

      case 'Movers':
        return (
          <section>
            <h3>Moving From</h3>
            <Row id="movers-from">
              <Col
                md="auto"
                xs={12}
                className="flex-md-grow-1 flex-md-shrink-1 content"
              >
                <Row>
                  <Col xs={12} md={6} className="form-item">
                    <Form.Group controlId="MoveDate">
                      <Form.Label>Expected date of move</Form.Label>
                      <Form.Control
                        type="date"
                        value={formatDisplayDate(moverInfo.moveDate)}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value || value === '') {
                            setMoverInfo((prevState) => ({
                              ...prevState,
                              moveDate: new Date(value),
                            }));
                          }
                        }}
                        placeholder="MM/DD/YYYY"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={6}
                    className="d-flex d-align-items-center form-item"
                  >
                    <Form.Group>
                      <Form.Label>
                        Does your building have an elevator?
                      </Form.Label>
                      <div className="form-radios">
                        {ElevatorList.map((item, index) => (
                          <Form.Check
                            name="FromHasElevator"
                            key={index}
                            label={item}
                            type="radio"
                            id={`inline-radio-${index}`}
                            onChange={() => setMoverFrom('hasElevator', item === 'Yes')}
                            checked={
                              !(
                                moverInfo.fromProperty.hasElevator
                                ^ (item === 'Yes')
                              )
                            }
                          />
                        ))}
                      </div>
                    </Form.Group>
                  </Col>
                  {!moverInfo.fromProperty.hasElevator && (
                  <Col xs={12} md={6} className="form-item">
                    <Form.Group controlId="FromFloor">
                      <Form.Label>
                        What floor is your home located on?
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={moverInfo.fromProperty.floor}
                        onChange={(e) => setMoverFrom('floor', e.target.value)}
                      >
                        {[1, 2, 3, 4, 5, 6].map((i) => (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  )}
                </Row>
                <StreetInput
                  label="Address - Moving From"
                  addresses={moverInfo.fromProperty.address}
                  onChange={(add) => setMoverFrom('address', add)}
                />
              </Col>
            </Row>
          </section>
        );

      default:
        return `${category}`;
    }
  };

  const moversNext = () => (
    <section>
      <h3>Moving To</h3>
      <Row id="movers-to">
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="d-flex d-align-items-center form-item">
              <Form.Group>
                <Form.Label>Does your building have an elevator?</Form.Label>
                <div className="form-radios">
                  {ElevatorList.map((item, index) => (
                    <Form.Check
                      name="ToHasElevator"
                      key={index}
                      label={item}
                      type="radio"
                      id={`inline-radio-${index}-to`}
                      checked={
                        !(moverInfo.toProperty.hasElevator ^ (item === 'Yes'))
                      }
                      onChange={() => setMoverTo('hasElevator', item === 'Yes')}
                    />
                  ))}
                </div>
              </Form.Group>
            </Col>
            {!moverInfo.toProperty.hasElevator && (
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="ToFloor">
                <Form.Label>What floor is your home located on?</Form.Label>
                <Form.Control
                  as="select"
                  value={moverInfo.toProperty.floor}
                  onChange={(e) => setMoverTo('floor', e.target.value)}
                >
                  {[1, 2, 3, 4, 5, 6].map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            )}
          </Row>
          <StreetInput
            label="Address - Moving To"
            addresses={moverInfo.toProperty.address}
            onChange={(add) => setMoverTo('address', add)}
          />
        </Col>
      </Row>
    </section>
  );

  const renderContact = () => (
    <section>
      <h3>Contact information</h3>
      <Row>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  isInvalid={errors.firstName}
                  type="text"
                  value={contactInfo.firstName}
                  onChange={(e) => {
                    if (validateName(e.target.value)) {
                      setErrors({
                        ...errors,
                        firstName: false,
                      });
                    }
                    if (validateName(e.target.value)) {
                      setContactVal('firstName', e.target.value);
                    }
                  }}
                  onBlur={() => setErrors({
                    ...errors,
                    firstName: !contactInfo.firstName,
                  })}
                />
                {/* <Form.Control.Feedback type="invalid">
                  Please enter a valid first name.
                </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={contactInfo.lastName}
                  onChange={(e) => {
                    if (validateName(e.target.value)) {
                      setContactVal('lastName', e.target.value);
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="email">
                <Form.Label>Email (to receive quotes)</Form.Label>
                <Form.Control
                  isInvalid={errors.email}
                  type="text"
                  defaultValue={contactInfo.email}
                  onChange={(e) => {
                    if (validateEmail(e.target.value)) {
                      setErrors({
                        ...errors,
                        email: false,
                      });
                    }
                    setContactVal('email', e.target.value);
                  }}
                  onBlur={() => setErrors({
                    ...errors,
                    email: !contactInfo.email || !validateEmail(contactInfo.email),
                  })}
                />
                {/* <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="phone">
                <Form.Label>Phone (will not be shared with anyone)</Form.Label>
                <Form.Control
                  type="text"
                  value={contactInfo.phone || ''}
                  onChange={(e) => {
                    const value = formatPhoneNumber(e.target.value);
                    if (value || value === '') {
                      setContactVal('phone', value);
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );

  const dispatch = useDispatch();

  const submit = () => {
    const professionalsWithCategory = [...professionals];
    professionalsWithCategory.map((item) => {
      item.category = category;
      return true;
    });

    if (category === 'MortgageBrokers') {
      dispatch(
        Actions.doQuoteMortgates({
          purchaseInfo,
          financialInfo,
          contactInfo,
          professionals: professionalsWithCategory,
          category,
        }),
      );
    } else if (category === 'RealEstateAgents') {
      dispatch(
        Actions.doQuoteMortgates({
          // userInfo,
          contactInfo,
          purchaseInfo,
          professionals: professionalsWithCategory,
          category,
        }),
      );
    } else if (
      category === 'Attorneys'
      || category === 'HomeInspectors'
      || category === 'HomeownersInsurance'
    ) {
      dispatch(
        Actions.doQuoteMortgates({
          userInfo,
          contactInfo,
          professionals: professionalsWithCategory,
          category,
        }),
      );
    } else if (category === 'Movers') {
      dispatch(
        Actions.doQuoteMortgates({
          moverInfo,
          contactInfo,
          professionals: professionalsWithCategory,
          category,
        }),
      );
    }
    handleClose();
    setStep(1);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setStep(1);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="quote-modal"
    >
      <Modal.Header closeButton>
        <Container className="px-md-5">
          <Row>
            <Col>
              <h3>Request Quote</h3>
              <h6 className="mb-0">
                Step
                {' '}
                {step}
                {' '}
                of
                {' '}
                {getLastStep()}
              </h6>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <CSSTransition
          in={step === 1}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames={`slide-${direction}`}
          unmountOnExit
          mountOnEnter
        >
          {renderForms()}
        </CSSTransition>
        <CSSTransition
          in={step === 2}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames={`slide-${direction}`}
          unmountOnExit
          mountOnEnter
        >
          {category === 'Movers'
            ? moversNext()
            : renderContact()}
        </CSSTransition>
        <CSSTransition
          in={step === 3}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames={`slide-${direction}`}
          unmountOnExit
          mountOnEnter
        >
          {renderContact()}
        </CSSTransition>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          variant="outline"
          className="btn-outline-hyponia btn-outline px-4"
          onClick={step === 1 ? handleClose : () => {
            setDirection('right');
            setStep(step - 1);
          }}
        >
          {step !== 1 ? 'Back' : 'Cancel'}
        </Button>
        <Button
          className="ml-4 btn-hyponia btn px-4"
          onClick={step === getLastStep() ? submit : () => {
            setDirection('left');
            setStep(step + 1);
          }}
          disabled={
            step === getLastStep()
            && (!contactInfo.firstName || !validateEmail(contactInfo.email))
          }
        >
          {step === getLastStep() ? 'Submit' : 'Next'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
