import { ActionTypes } from '../actions';

const defaultState = {};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_PROFESSIONALS:
        return {
          ...state,
          isProfessionalsLoading: true,
        };
      case ActionTypes.LOADED_PROFESSIONALS:
        return {
          ...state,
          professionals: action.payload.professionals,
          category: action.payload.category,
          isProfessionalsLoading: false,
        };

      case ActionTypes.DOING_QUOTE_MORTGATES:
        return {
          ...state,
          doingQuoteMortgates: true,
        };

      case ActionTypes.DONE_QUOTE_MORTGATES:
        return {
          ...state,
          doingQuoteMortgates: false,
          doneQuoteMortgates: true,
        };

      case ActionTypes.ERROR_QUOTE_MORTGATES:
        return {
          ...state,
          doingQuoteMortgates: false,
        };

      default:
        return state;
    }
  } catch (error) {
    console.log('Error in professionals reducer:', error);
  }

  return null;
};
